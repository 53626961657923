import React from "react"
import SEO from "components/seo"
import Heading from "components/Heading"
import HeroImageFull from "components/HeroImageFull"
import VideoWrapper from "components/VideoWrapper"

const ViewTestimonials = ({ pageData }) => {
  const { listTestimonial, heading, page } = pageData

  return (
    <React.Fragment>
      <SEO title={page} />
      <div className="max-container real-hero wrapper--testimonials--hero">
        <HeroImageFull
          heroTextClassname="hero-text-testimonials mobile--is-center"
          imageName="testimonialsHeroImage"
          imageProps={{
            imgStyle: {
              objectPosition: "center top",
            },
          }}
        >
          <Heading className="is-heading">{heading}</Heading>
        </HeroImageFull>
      </div>

      <div className="wrapper--testimonials--blocks">
        {listTestimonial.map(({ title, youtubeId }, index) => (
          <div
            key={`testimonial-item--${index}`}
            className="max-container wrapper--testimonials--block"
          >
            <Heading className="title">{title}</Heading>
            <VideoWrapper videoId={youtubeId} />
          </div>
        ))}
      </div>
    </React.Fragment>
  )
}

export default ViewTestimonials
